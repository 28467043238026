import React from 'react';
import Sectitle from '../Title/Sectitle';
import Reveal from 'react-reveal/Reveal/';


const Company = () => {
    return (
        <section className="h_blog_area sec_pad" id='company'>
            <div className="container">
                <Sectitle Title="Company" TitleP="Sensoft focuses on employing modern technologies to tackle a wide spectrum of business and industrial problems. Our team has diverse expertise spanning from embedded software to modern web technologies and equipped with extensive industrial experience." sClass="hosting_title text-center" />
                <div className="h_features_img" style={{ textAlign: 'center' }}>
                    <Reveal effect="fadeInUp" duration={1400}><img src={require('../../img/seo/features_img.png')} alt="" /></Reveal>
                </div>
            </div>
        </section>
    )
}

export default Company;