import React from 'react';

const ZHC4922 = () => {
    return (
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <img src={require("../../img/iotrouter/4922-16.jpg")} alt="" className="iotRouterImage" />
                                <h6 className="f_p f_600 f_size_18 t_color3">ZHC 4922</h6>
                            </div>
                            <div className="info_item">
                                <h6>Communication method:</h6>
                                <p> 4G (Full Netcom)</p>
                            </div>
                            <div className="info_item">
                                <h6>Interface type:</h6>
                                <p>DI / DO / AI / RS485</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">
                                    ZHC4922 is an industrial-grade 4G RTU that provides 4 circuit DI, 4 circuit DO, 4 circuit AI and 1 circuit RS485 interface,
                                    supports local logic processing operations, automatic acquisition and active reporting, MQTT RTU / TCP adaptive, automatic calibration threshold reporting, 
                                    configuration software docking, IO detection of wet and dry nodes, support one-to-one, many-to-one network, configuration software interface window visualization, 
                                    realize user on-site or remote connection, debugging, configuration equipment. It can be widely used in power system, industrial monitoring, traffic management, 
                                    environmental monitoring, meteorology, water conservancy, coal mine, petroleum, new energy and other industries, providing strong support for the project's communication networking and wireless transmission.</p>
                                <div className="send_email_btn"><a href="mailto:support@sensoft.tech?subject=ZHC 4922 Details" className="hosting_btn btn_hover wow fadeInUp" data-wow-delay="0.7s">Learn More</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt_60 ">
                    <ul className="nav nav-tabs develor_tab mb-30" id="myTab2" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active f_500" data-tab="tab_one" id="product_details-tab" data-toggle="tab" href="#product_details" role="tab" aria-controls="product_details" aria-selected="true">Product Details </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link f_500" data-tab="tab_two" id="product_parameter-tab" data-toggle="tab" href="#product_parameter" role="tab" aria-controls="product_parameter" aria-selected="false">Product Parameter</a>
                        </li>
                    </ul>
                    <div className="tab-content developer_tab_content">
                        <div className="tab-pane fade show active" id="product_details" role="tabpanel" aria-labelledby="product_details-tab">
                            <div className="iotrouter_details_img_container">
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/01.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/02.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/03.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">客户自建服务器</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/04.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">组态软件对接</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/05.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">客户无云服务器</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/06.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">设备透传组网(数转电台)</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/07.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/08.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">RS485 自动采集</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/09.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">模拟量自动校准阈值上报</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/10.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">支持Modbus RTU/TCP 协议自动识别 </h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/11.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/15.gif")} alt=""></img>
                                <h3 className="iotrouter_details_h3">TCP Client通信</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/13.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">HTTP Client通信</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/14.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/12.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/17.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/18.jpg")} alt=""></img>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/19.jpg")} alt=""></img>
                                <h3 className="iotrouter_details_h3">Product Size</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/21.jpg")} alt=""></img>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="product_parameter" role="tabpanel" aria-labelledby="product_parameter-tab">
                            <div className="iotrouter_details_img_container">
                                <h3 className="iotrouter_details_h3">Product Parameter</h3>
                                <img className="iot_img_fluid mb_20" src={require("../../img/iotrouter/ZHC4922_details_img/22.jpg")} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ZHC4922;