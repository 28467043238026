import React from 'react';
import HostingFeaturesItem from './HostingFeaturesItem';

const HostingFeatures =()=>{
    return(
        <section className="hosting_features_area sec_pad" id="solutions">
            <div className="container">
                <HostingFeaturesItem hImage="business1.png" hTitle="Solutions" hPragraph="We offer technical consultations as well as end-to-end solutions to assist businesses and industries to stay ahead of competition by adopting modern technologies in Cloud, IoT and AI."
                icon="icon7.png" icon2="icon8.png" text="Technical Consultations"  tdescription="Advice customers in solving business problems using technologies." text2="Modern Technologies" tdescription2="Deliver digital solutions to help grow business opportunities and improves operational efficiencies."/>
            </div>
        </section>
    )
}

export default HostingFeatures;