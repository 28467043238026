import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [{ lat: 5.333820, lng: 100.265810 }],
      showingInfoWindow: true
    };
  }

  // displayMarkers = () => {
  //   return this.state.stores.map((store, index) => {
  //     return (
  //       <Marker
  //         key={index}
  //         id={index}
  //         position={{
  //           lat: store.latitude,
  //           lng: store.longitude,
  //         }}
  //         onClick={() => console.log("You clicked me!")}
  //       />
  //     );
  //   });
  // };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true
    });

  render() {
    const mapStyles = {
      height: 350,
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      padding: 0
    };
    return (
      <section className="contact_map">
        <Map
          google={this.props.google}
          zoom={18}
          initialCenter={{ lat: 5.340309, lng: 100.281728 }}
          style={mapStyles}
        >
          {/* {this.displayMarkers()} */}
          <Marker
            title={'AI | App | Cloud | IoT - Sensoft Technologies'}
            name={'Sensoft Technologies'}
            position={{ lat: 5.340093, lng: 100.281745 }}
            onClick={this.onMarkerClick}
          />
          <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            <div>
              <h6 style={{ margin: '0px' }}>Sensoft Technologies</h6><br/>
              <i class="fa fa-building"></i>&nbsp;Sains@USM, CREST Blk A 1st Flr<br/>
              <span>&nbsp;No. 10 Persiaran Bukit Jambul</span><br/>
              <span>&nbsp;11900 Bayan Lepas, Penang</span><br/>
              <span>&nbsp;Malaysia</span>&nbsp;&nbsp;&nbsp;<a href="https://www.google.com/maps/dir/?api=1&destination=5.340093,100.281745" target="_blank"><i className="fa fa-map" aria-hidden="true"/></a><br/><br/>
              <i class="fa fa-phone"></i> <a href="tel:+60162684847">+6016 268-4847</a><br/>
              <i class="fa fa-envelope"></i> <a href="mailto:support@sensoft.tech">support@sensoft.tech</a>
            </div>
          </InfoWindow>
        </Map>

      </section>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDgf0-GzSZQ-5nmXpr-iwVKNTnQUEqF-lw",
})(MapContainer);
