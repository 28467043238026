import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

const Partners = () => {
    return (
        <React.Fragment>
            <section className="seo_service_area sec_pad" id='partners'>
                <div className="container">
                    <SeoTitle Title='Partners' TitleP='We work closely with our partners to deliver the best-in-class solutions for a diverse range of customer applications.' />
                    <div className="row seo_service_info">
                        <Fade bottom duration={500} >
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={require('../../img/alicloud_logo.png')} alt="" className="card_alicloud_logo" />
                                    </div>
                                    <h4>Alibaba Cloud</h4>
                                    <p>Alibaba Cloud is the top cloud computing service provider in Asia serving more than 3 million global businesses in over 200 countries. Alibaba Cloud technologies are powering a multitude of applications from e-commerce, enterprise applications to the Internet of Things.</p>
                                    <div className="align_btm_arrow"><a href="https://my.alibabacloud.com/" className="partners_btn btn_hover wow fadeInUp" data-wow-delay="0.7s" target="blank">Learn More</a></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={750}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={require('../../img/iotrouter_logo.png')} alt="" className="card_partner_logo" />
                                    </div>
                                    <h4>IOTRouter</h4>
                                    <p>IOTRouter products are developed by Chengdu Zongheng Intelligent Control Technology, a company specializes in Internet-of-Things gateway which are widely adopted in Smart City, Smart Farm, Smart Home and Hydrologic Monitoring applications.</p>
                                    <div className="align_btm_arrow"><a href="http://www.iotrouter.com/" className="partners_btn btn_hover wow fadeInUp" data-wow-delay="0.7s" target="blank">Learn More</a></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={require('../../img/uft_logo.png')} alt="" className="card_partner_logo" />
                                    </div>
                                    <h4>Urban Farm Tech</h4>
                                    <p>Urban Farm Tech designs and builds custom aquaponic farm systems to promote a safer and sustainable way to grow food. Their expertise is in engineering, construction and commisioning of farming systems.</p>
                                    <div className="align_btm_arrow"><a href="https://www.urbanfarmtech.my/" className="partners_btn btn_hover wow fadeInUp" data-wow-delay="0.7s" target="blank">Learn More</a></div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            {/* <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img.png')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>Get tips & tricks on how to skyrocket your sales.</h2>
                                    <p>Faff about only a quid blower I don't want no agro bleeding chimney pot burke tosser cras nice one boot fanny.!</p>
                                    <div className="media seo_features_item">
                                        <div className="icon"><img src={require('../../img/seo/icon4.png')} alt="" /></div>
                                        <div className="media-body">
                                            <h3>Reporting & Analysis</h3>
                                            <p>Naff are you taking the piss say blow off faff about wellies richard.</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon two"><img src={require('../../img/seo/icon3.png')} alt="" /></div>
                                        <div className="media-body">
                                            <h3>Reporting & Analysis</h3>
                                            <p>Naff are you taking the piss say blow off faff about wellies richard.</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img_two.png')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">Get tips & tricks on how to skyrocket your sales.</h2>
                                    <h6 className="wow fadeInUp">What a plonker bamboozled so I said say what a load of rubbish owt to do with me haggle.</h6>
                                    <p className="wow fadeInUp">Cheeky bugger gosh codswallop chap bamboozled blatant cracking goal brown bread, pear shaped cor blimey guvnor easy peasy lemon squeezy hotpot spiffing good time, chancer a spend a penny spiffing I don't want no agro tinkety tonk old fruit.</p>
                                    <a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Learn More</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section> */}
        </React.Fragment>
    )
}

export default Partners;
