import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ScrollToTopRoute from "./ScrollToTopRoute";
/*------ Pages-----*/
import Contact from "./Pages/Contact";
import HomeSensoft from "./Pages/HomeSensoft";
import NotFound from "./Pages/404";
import IotRouter from "./Pages/IotRouter";
import IotRouterProductDetails from "./Pages/IotRouterProductDetails";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <>
        <Router>
          <Switch>
            <ScrollToTopRoute exact={true} path={"/"} component={HomeSensoft} />
            <ScrollToTopRoute path="/IotRouter" component={IotRouter} />
            <ScrollToTopRoute path="/IotRouterProductDetails/:product" component={IotRouterProductDetails} />
            <ScrollToTopRoute path="/Contact" component={Contact} />
            <ScrollToTopRoute component={NotFound} />
          </Switch>
        </Router>
        <CookieConsent
          // debug={true}
          location="bottom"
          cookieName="SensoftCookieConsent"
          style={{ background: "#2B373B" }}
          // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          // declineButtonStyle={{ marginRight: "30%" }}
          expires={1000}
          enableDeclineButton={true}
          setDeclineCookie={false}
          flipButtons={false}
          onDecline={() => {
            document.cookie.split(";").forEach(function (c) {
              console.log(document.cookie);
              document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;domain=sensoft.tech;expires=" + new Date().toUTCString() + ";path=/");
            });
            alert('You will be redirected to Google, please come back again!');
            window.location.href = "https://google.com";
          }}
        >
          This website uses cookies to help us improve our services and to enhance user experience.
            </CookieConsent>
      </>
    );
  }
}

export default App;
