import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

class FooterSensoft extends Component {
    render() {
        let FooterData = this.props.FooterData;
        let { fClass } = this.props;
        return (
            <footer className={`footer_area h_footer_dark ${fClass}`}>
                <div className="container">
                    <div className="row">
                        <Reveal effect="fadeInLeft" duration={500} key={1}>
                            <div className="col-lg-4 col-sm-6" >
                                <div className="f_widget dark_widget company_widget" data-wow-delay="0.2s">
                                    <a href="/" className="f-logo"><img src={require("../../img/sensoft_white_logo.png")} alt="" /></a>
                                    <p>© 2023 Sensoft Technologies.</p>
                                </div>
                            </div>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={500} key={1}>
                            <div className="col-lg-3 col-sm-6" >
                                <div className="footerInfo footer-mt-20" data-wow-delay="0.2s">
                                    <p><strong style={{ color: 'white', fontWeight: '400' }}>Sensoft Technologies Sdn Bhd</strong> <br />
                                        <small>Reg No. 201601037479 (1208420-D)<br /></small></p>
                                    <p>
                                        Sains@USM, CREST Blk A 1st Flr<br />
                                    No. 10 Persiaran Bukit Jambul<br />
                                    11900 Bayan Lepas, Penang<br />
                                    Malaysia<br />
                                    </p>
                                </div>
                            </div>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={500}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footerInfo footer-mt-70 f_widget dark_widget about-widget" data-wow-delay="0.2s">
                                    <ul className="list-unstyled f_list">
                                        <li><span className="fa fa-phone" ></span> &nbsp; <a href="tel:3024437488">+6016 268-4847</a></li>
                                        <li><span className="fa fa-envelope"></span>&nbsp;&nbsp;&nbsp;<a href="mailto:support@sensoft.tech">support@sensoft.tech</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Reveal>
                        {/* <Reveal effect="fadeInLeft" duration={500}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="f_widget dark_widget about-widget pl_70">
                                    <h3 className="f-title f_500 t_color f_size_18 mb_40">Menu</h3>
                                    <ul className="list-unstyled f_list">
                                        <li><Link to="/">Home</Link></li>
                                        <li><a href="/#company" >Company</a></li>
                                        <li><a href="/#solutions">Solutions</a></li>
                                        <li><Link to="/Contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Reveal>

                        <Reveal effect="fadeInLeft" duration={500}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="f_widget dark_widget about-widget pl_70">
                                    <h3 className="f-title f_500 t_color f_size_18 mb_40">Partners</h3>
                                    <ul className="list-unstyled f_list">
                                        <li><Link to="/IotRouter">IOT Router</Link></li>
                                        <li><Link to="https://my.alibabacloud.com/?utm_content=se_1004171618&gclid=EAIaIQobChMIkf2OvOLY6QIVyhErCh2oewA7EAAYASAAEgKpIvD_BwE">Ali Cloud</Link></li>
                                        <li><Link to="https://www.urbanfarmtech.my/">Urban Farm</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Reveal> */}


                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterSensoft;