import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';

class HostingActionTwo extends Component {
    render(){
        return(
            <section className="h_action_promo_area">
                <div className="overlay_bg" style={{backgroundImage: "url(" + (require("../img/hosting/hosting_action_bg.png")) + ")"}}></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="h_promo_content">
                                <h2>Start growing with SENSOFT today!</h2>
                            </div>
                        </div>
                        <div className="col-md-4 text-right">
                            <Link to="/Contact" className="hosting_btn btn_hover">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HostingActionTwo;
