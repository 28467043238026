import React, { Component } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import ZHC4931 from '../components/IotRouter/ZHC4931';
import ZHC4922 from '../components/IotRouter/ZHC4922';
import ZHC1921 from '../components/IotRouter/ZHC1921';
import ZHT0062 from '../components/IotRouter/ZHT0062';
import FooterSensoft from '../components/Footer/FooterSensoft';
import FooterData from '../components/Footer/FooterData';

class IotRouterProductDetails extends Component {
    switchProduct() {
        switch (this.props.match.params.product) {
            case 'ZHC4931':
                return <ZHC4931 />
            case 'ZHC4922':
                return <ZHC4922 />
            case 'ZHC1921':
                return <ZHC1921 />
            case 'ZHT0062':
                return <ZHT0062 />
            default:
                return this.props.history.push('/404');
        }
    }
    render() {
        console.log(this.props.match.params.product);
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo" />
                <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/contact_banner.jpg" Ptitle="IoT Routers" Pdescription="Dependable, assured connectivity for extreme environments." />
                {
                    this.switchProduct()
                }
                <FooterSensoft FooterData={FooterData} />
            </div>
        )
    }
}
export default IotRouterProductDetails;